import {FilePdf24} from "@bphxd/ds-core-react/lib/icons";
import {NUMBER_OF_DECIMALS} from "constants/common";
import ColumnHighlight from "modules/GlobalMassBalance/components/ColumnHighlight";
import {getFilterValue} from "modules/GlobalMassBalance/utils";
import moment from "moment";
import {Link} from "react-router-dom";
import {UncontrolledTooltip} from "reactstrap";
import {getFormattedNumberWithCheck} from "utils/numberUtil";

const getIncomingColumnsGfTrsSpain = (
  dateFormat,
  decimalFormat,
  country,
  siteReferenceId,
  divisionId,
) => [
  {
    header: "Incoming",
    columns: [
      {
        header: "Physical receipt date",
        accessorKey: "physicalReceiptDate",
        key: "physicalReceiptDate",
        visible: true,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFn",
        cell: ({cell}) => {
          return cell.getValue() && moment(cell.getValue()).format(dateFormat);
        },
      },
      {
        header: "Document",
        accessorKey: "incomingDocument",
        key: "incomingDocument",
        visible: true,
        cell: ({cell, row}) => {
          const tooltipId = `incomingDocument${row?.id}`;
          return (
            <>
              <Link
                to={{
                  pathname: `/document-manager/incoming/${country}/detailedView/${encodeURIComponent(
                    row?.original?.certificateInboundEuId,
                  )}`,
                  search: `?siteReferenceId=${siteReferenceId}&divisionId=${divisionId}`,
                }}
                className="link-dark"
                id={tooltipId}
              >
                <u className="flex">
                  {cell.getValue() && <FilePdf24 />}
                  <ColumnHighlight
                    value={cell.getValue()}
                    columnFilter={getFilterValue(cell, "incomingDocument")}
                    hasEllipsis
                    isCertificate
                  />
                </u>
              </Link>
              <UncontrolledTooltip placement="bottom" target={tooltipId}>
                {cell.getValue() ?? ""}
              </UncontrolledTooltip>
            </>
          );
        },
      },
      {
        header: "Raw material",
        accessorKey: "rawMaterial",
        key: "rawMaterial",
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Country of origin",
        accessorKey: "countryOfOrigin",
        key: "countryOfOrigin",
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "ghgTotalMj",
        key: "ghgTotalMj",
        visible: true,
        dataType: "number",
        accessorFn: (row) => {
          return row?.ghgTotalMj?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Sustainable quantity m³",
        accessorKey: "sustainableQuantityM3",
        key: "sustainableQuantityM3",
        visible: true,
        disabled: true,
        dataType: "number",
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
        filterFn: "numberFilterFn",
      },
      {
        header: "Issuance date",
        accessorKey: "issuanceDate",
        key: "issuanceDate",
        visible: false,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFn",
        cell: ({cell}) => {
          return cell.getValue() && moment(cell.getValue()).format(dateFormat);
        },
      },
      {
        header: "Quantity m³",
        accessorKey: "productQtyM3",
        key: "productQtyM3",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.productQtyM3?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Quantity MT",
        accessorKey: "productQtyMt",
        key: "productQtyMt",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.productQtyMt?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Supplier",
        accessorKey: "supplier",
        key: "supplier",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Transport system",
        accessorKey: "transportSystem",
        key: "transportSystem",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Vessel name",
        accessorKey: "vesselName",
        key: "vesselName",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Transport loss/gain",
        accessorKey: "transportLossGain",
        key: "transportLossGain",
        visible: false,
        meta: {
          filterVariant: "search",
        },
      },
      {
        header: "Certified",
        accessorKey: "certified",
        key: "certified",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Received quantity MT",
        accessorKey: "receivedQuantityMT",
        key: "receivedQuantityMT",
        visible: false,
        filterFn: "numberFilterFn",
        dataType: "number",
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Sustainable quantity MT",
        accessorKey: "sustainableQuantityMT",
        key: "sustainableQuantityMT",
        visible: false,
        filterFn: "numberFilterFn",
        dataType: "number",
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "GHG savings %",
        accessorKey: "ghgSavings",
        key: "ghgSavings",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.ghgSavings?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
    ],
  },
];

export default getIncomingColumnsGfTrsSpain;
