import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

const ALLOCATION_MUTATION = gql`
  mutation bioLcAllocation($event: bioLcAllocationRequest!) {
    bioLcAllocation(event: $event) {
      statusCode
      error
    }
  }
`;

const AllocationAPI = (data) =>
  client.mutate({
    mutation: ALLOCATION_MUTATION,
    variables: {
      ...data,
    },
    refetchQueries: [
      "bioLcGetAvailableIncomingRecordsApi",
      "bioLcGetAllocationSalesApi",
    ],
  });

const ALLOCATION_OAS_LAYER_MUTATION = gql`
  mutation bioLcAllocateOASOutboundData(
    $event: bioLcAllocateOASOutboundDataRequest!
  ) {
    bioLcAllocateOASOutboundData(event: $event) {
      statusCode
    }
  }
`;

const AllocationOASLayerAPI = (data) =>
  client.mutate({
    mutation: ALLOCATION_OAS_LAYER_MUTATION,
    variables: {
      ...data,
    },
    refetchQueries: [
      "bioLcGetAvailableIncomingRecordsApi",
      "bioLcGetAllocationOASLayerApi",
    ],
  });

export {
  ALLOCATION_MUTATION,
  ALLOCATION_OAS_LAYER_MUTATION,
  AllocationAPI,
  AllocationOASLayerAPI,
};
