/* eslint-disable jsx-a11y/click-events-have-key-events */
import {useLazyQuery} from "@apollo/client";
import {Down32, Kebab24, Up32} from "@bphxd/ds-core-react/lib/icons";
import {
  COPRO_US_DISCARD_SHIPMENT,
  COPRO_US_SHIPMENTS_API_ALLOCATE_BATCH_TO_SHIPMENT,
  COPRO_US_SHIPMENTS_API_EDIT_SHIPMENT_ID,
} from "graphql/coprocessing/shipments";
import ActiveBatchCard from "modules/co-processing/components/Shipments/ActiveBatchCard";
import Banner from "modules/co-processing/components/Shipments/Banner";
import CopyToClipboard from "modules/co-processing/components/Shipments/CopyToClipboard";
import ShipmentCard from "modules/co-processing/components/Shipments/ShipmentCard";
import {MULTI_PART_ICON_FLAG} from "modules/co-processing/constants/batches";
import {COPRO_URL} from "modules/co-processing/constants/coProcessing";
import {
  ACTION_OPTIONS,
  BANNER_TYPE,
  BUTTONS,
  ERRORS,
  SHIPMENT_FIELD_KEYS,
  SHIPMENT_SUCCESS,
  SHIPMENT_TYPE,
  TITLES,
} from "modules/co-processing/constants/shipments";
import buildAllocationDetails from "modules/co-processing/helpers/buildAllocationDetails";
import combineShipments from "modules/co-processing/helpers/combineShipments";
import useModal from "modules/co-processing/helpers/useModal";
import PropTypes from "prop-types";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import RecallPopover from "../Historical/RecallPopover";
import BatchChecklistProgress from "../Shared/BatchChecklist/BatchChecklistProgress";
import BatchesStatusChip from "../Shared/BatchesStatusChip";
import BOLModal from "./BOLModals/BOLModal";
import ResetAllocationModal from "./BulkReset/BulkAllocationResetModal";
import CommitAllocationPopover from "./CommitAllocationPopover";
import ShipmentsDeletePopover from "./ShipmentsDeletePopover";
import SplitShipmentsModal from "./SplitModal/SplitShipment";
import "./index.scss";

const extractStatusDetails = (batchDetails) => {
  return {
    batch_created_status: batchDetails?.batch_created_status,
    shipment_linked_status: batchDetails?.shipment_linked_status,
    c14_linked_status: batchDetails?.c14_linked_status,
    feedstock_allocated_status: batchDetails?.feedstock_allocated_status,
    // emts_submitted_status: batchDetails?.emts_submitted_status,
    // state_submitted_status: batchDetails?.state_submitted_status,
    // state_final_uploaded_status: batchDetails?.state_final_uploaded_status,
    completion_percentage: batchDetails?.completion_percentage,
  };
};

const getCardDetails = (type, data) => {
  const isActiveBatches = type === TITLES.ACTIVE_BATCHES;
  const cardTitle = isActiveBatches ? data?.batch_id : data?.shipment_id;
  const status = isActiveBatches
    ? data?.batch_status
    : data.parts.length > 1
    ? MULTI_PART_ICON_FLAG
    : data?.parts[0]?.shipment_status || "ACTIVE";

  return {
    status,
    cardTitle,
    isActiveBatches,
  };
};

const getActualVolumeStatus = (parts) => {
  if (!parts || parts?.length === 0) {
    return false;
  }
  return parts?.some((part) => part?.total_actual_volume !== null);
};

const getActionsForShipmentType = (
  shipmentType,
  setIsOpenBOLModal,
  setIsOpenSplitModal,
  shipmentStatus, // currently only used for DTN and BTU as parts[0].shipment_status
  setShowPopover,
  navigate,
  parts,
  toggleResetModal,
  setIsOpenRecallModal,
  discardShipment,
) => {
  const hasActualVolume = getActualVolumeStatus(parts);
  const allPartsWithoutBatchId = parts?.every((part) => part?.batch_id == null);

  const hasCommitted = parts?.some(
    (part) => part?.shipment_status?.toLowerCase() === "committed",
  );

  switch (shipmentType) {
    case SHIPMENT_TYPE.PIPELINE:
    case SHIPMENT_TYPE.VESSEL: {
      return (
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              navigate();
            }}
          >
            {ACTION_OPTIONS.VIEW_DETAILS}
          </DropdownItem>
          <DropdownItem
            className="btn"
            onClick={(e) => {
              e.stopPropagation();
              setIsOpenSplitModal(true);
            }}
          >
            {ACTION_OPTIONS.EDIT_SPLIT}
          </DropdownItem>
          {hasCommitted && (
            <DropdownItem
              onClick={(e) => {
                e.stopPropagation();
                setIsOpenRecallModal(true);
              }}
            >
              {ACTION_OPTIONS.RECALL_SHIPMENT}
            </DropdownItem>
          )}

          {allPartsWithoutBatchId && parts?.length === 1 && (
            <DropdownItem
              onClick={(e) => {
                e.stopPropagation();
                discardShipment();
              }}
            >
              {ACTION_OPTIONS.DISCARD_SHIPMENT}
            </DropdownItem>
          )}
        </DropdownMenu>
      );
    }
    case SHIPMENT_TYPE.TRUCK_RACK:
      return (
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              navigate();
            }}
          >
            {ACTION_OPTIONS.VIEW_DETAILS}
          </DropdownItem>
          {hasActualVolume && (
            <DropdownItem
              onClick={(e) => {
                e.stopPropagation();
                setIsOpenBOLModal(true);
              }}
            >
              {ACTION_OPTIONS.ALLOCATE_BOLS}
            </DropdownItem>
          )}
          <DropdownItem
            onClick={(e) => {
              e.stopPropagation();
              toggleResetModal();
            }}
          >
            {ACTION_OPTIONS.BULK_ALLOCATION_RESET}
          </DropdownItem>
          {hasCommitted && (
            <DropdownItem
              onClick={(e) => {
                e.stopPropagation();
                setIsOpenRecallModal(true);
              }}
            >
              {ACTION_OPTIONS.RECALL_SHIPMENT}
            </DropdownItem>
          )}
          <DropdownItem
            disabled={shipmentStatus !== "Active"}
            onClick={(e) => {
              e.stopPropagation();
              setShowPopover(true);
            }}
          >
            {ACTION_OPTIONS.DELETE_SHIPMENT}
          </DropdownItem>
        </DropdownMenu>
      );
    case SHIPMENT_TYPE.BACK_TO_UNIT:
      return (
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              navigate();
            }}
          >
            {ACTION_OPTIONS.VIEW_DETAILS}
          </DropdownItem>
          <DropdownItem
            disabled={!["Active", "In Progress"].includes(shipmentStatus)}
            onClick={(e) => {
              e.stopPropagation();
              setShowPopover(true);
            }}
          >
            {ACTION_OPTIONS.DELETE_SHIPMENT}
          </DropdownItem>
        </DropdownMenu>
      );
    case SHIPMENT_TYPE.INVENTORY_TRANSFER: {
      return (
        <DropdownMenu>
          {hasCommitted && (
            <DropdownItem
              onClick={(e) => {
                e.stopPropagation();
                setIsOpenRecallModal(true);
              }}
            >
              {ACTION_OPTIONS.RECALL_SHIPMENT}
            </DropdownItem>
          )}
          <DropdownItem
            disabled={shipmentStatus === "Committed"}
            onClick={(e) => {
              e.stopPropagation();
              setShowPopover(true);
            }}
          >
            {ACTION_OPTIONS.DELETE_SHIPMENT}
          </DropdownItem>
        </DropdownMenu>
      );
    }
    default:
      return <DropdownMenu></DropdownMenu>;
  }
};

const CollapsibleCard = ({
  cardType,
  cardData,
  lastSelectedCardRef,
  onCardSelect,
  refreshData,
  refetchShipments,
  getBatchTankCertifiedDate,
}) => {
  const [showDeletePopover, setShowDeletePopover] = useState(false);
  const [showCommitPopover, setShowCommitPopover] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState([]);
  const [cardId, setCardId] = useState({});
  const [errors, setErrors] = useState({});
  const [warnings, setWarnings] = useState({});
  const [isOpenBOLModal, setIsOpenBOLModal] = useState(false);
  const [isOpenSplitModal, setIsOpenSplitModal] = useState(false);
  const [isOpenRecallModal, setIsOpenRecallModal] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const toggleSplitModal = () => setIsOpenSplitModal(!isOpenSplitModal);
  const navigate = useNavigate();

  const [editShipmentSingleField, {data: shipmentDataEdit}] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_EDIT_SHIPMENT_ID,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        if (shipmentDataEdit?.bioLcCoproUsShipmentsApi?.error) {
          return toast.error(ERRORS.FAILED_INV_VOL);
        }
        return refreshData();
      },
    },
  );

  const {status, cardTitle, isActiveBatches} = useMemo(
    () => getCardDetails(cardType, cardData),
    [cardType, cardData],
  );

  const {isModalOpen: isResetModalOpen, toggleModal: toggleResetModal} =
    useModal("bulkResetModal");

  const updateValues = useCallback(
    (event, formId) => {
      const {name, value} = event.target;
      const idKey = formId.shipment_id ? "shipment_id" : "batch_id";
      const isTruckRack = cardData?.shipment_type === SHIPMENT_TYPE.TRUCK_RACK;
      const idValue =
        name === "batch_id" && !isActiveBatches && isTruckRack
          ? cardData?.shipment_id
          : formId[idKey];
      setFormData((prevData) => {
        return buildAllocationDetails(prevData, name, value, idKey, idValue);
      });
    },
    [isActiveBatches, cardData],
  );

  const updateErrors = useCallback((dataKey, error) => {
    setErrors((prevErrors) => ({...prevErrors, [dataKey]: error}));
  }, []);

  const updateWarnings = useCallback((dataKey, error, id) => {
    setWarnings((prevErrors) => ({...prevErrors, [dataKey]: error}));
    setCardId(id);
  }, []);

  const navigateToDetailsScreen = () => {
    navigate(`${COPRO_URL}/shipments/details`, {
      state: {
        id: cardData.shipment_id,
        details: cardData,
      },
    });
  };
  const [allocateBatchtoShipment, {data: res}] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_ALLOCATE_BATCH_TO_SHIPMENT,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        if (!res) return {};
        const {error, statusCode} = res.bioLcCoproUsShipmentsApi;
        if (error) {
          const errorMessage =
            statusCode === 400
              ? `${ERRORS.FAILED_ALLOCATION}. ${error}`
              : ERRORS.FAILED_ALLOCATION;
          return toast.error(errorMessage);
        }
        toast.success(SHIPMENT_SUCCESS);
        return refreshData();
      },
    },
  );

  const hasCommittedShipments = useMemo(() => {
    if (isActiveBatches) {
      if (!cardData?.shipments || cardData.shipments.length === 0) {
        return false;
      }
      return (
        cardData?.batch_status.toLowerCase() === "committed" &&
        cardData?.shipments?.every(
          (shipment) =>
            shipment?.shipment_status?.toLowerCase() === "committed",
        )
      );
    }
    if (!cardData?.parts || cardData.parts.length === 0) {
      return false;
    }
    return cardData?.parts?.every(
      (part) => part?.shipment_status?.toLowerCase() === "committed",
    );
  }, [isActiveBatches, cardData]);

  const isReadOnlyCard = useMemo(
    () => hasCommittedShipments,
    [hasCommittedShipments],
  );

  const isInventoryTransfer = useMemo(
    () =>
      !isActiveBatches &&
      cardData?.shipment_type === SHIPMENT_TYPE.INVENTORY_TRANSFER,

    [isActiveBatches, cardData],
  );

  const disableSaveButton = useMemo(() => {
    const hasErrors = Object.keys(errors).some((key) => errors?.[key]);
    const isFormEmpty = !formData.length;
    return isActiveBatches
      ? isLoading || hasErrors || isFormEmpty || hasCommittedShipments
      : isLoading || hasErrors || isFormEmpty;
  }, [isActiveBatches, hasCommittedShipments, isLoading, errors, formData]);

  const allocateCards = () => {
    allocateBatchtoShipment({
      variables: {
        batch_shipment_info: isActiveBatches
          ? formData
          : combineShipments(formData),
      },
    });
  };

  const validateShipmentCard = () => {
    const hasUserEnteredVolume = formData.some((data) =>
      Object.keys(data).includes(SHIPMENT_FIELD_KEYS.ACTUAL_SHIP_VOL),
    );
    const hasTotalVolume = cardData?.parts?.some(
      (data) => data.total_actual_volume != null,
    );
    if (!hasTotalVolume && !hasUserEnteredVolume) {
      // throw error & restrict saving
      return updateErrors("shipment_actual_sum", ERRORS.ACTUAL_VOLUME_REQUIRED);
    }
    return allocateCards();
  };

  const updateInventoryTransferVolume = () => {
    const fieldInfo = formData[0];
    const variables = {
      shipmentId: fieldInfo.shipment_id,
      update_field: {
        allocated_shipment_id: fieldInfo.shipment_id,
        field: "total_actual_volume",
        value: fieldInfo.actual_shipment_volume,
      },
    };
    return variables;
  };

  const saveValues = () => {
    if (!isActiveBatches) {
      if (isInventoryTransfer) {
        // currently using single value edit for inventory transfer volume edit
        const variables = updateInventoryTransferVolume();
        return editShipmentSingleField({variables});
      }
      return validateShipmentCard();
    }
    return allocateCards();
  };

  const [discardShipment] = useLazyQuery(COPRO_US_DISCARD_SHIPMENT);

  const handleDiscardAction = async () => {
    const {data} = await discardShipment({
      variables: {shipmentId: cardData?.shipment_id},
      skip: !cardData?.shipment_id,
    });

    if (data?.bioLcCoproUsShipmentsApi?.statusCode === 200) {
      toast.success("Successfully discarded shipment.");
      refreshData();
    } else {
      toast.error(
        data?.bioLcCoproUsShipmentsApi?.error || ERRORS.DISCARD_SHIPMENT,
      );
    }
  };

  const commitData = useMemo(() => {
    if (isActiveBatches) {
      const shipmentsId =
        cardData?.shipments?.map((shipment) => shipment.shipment_id) || [];
      const transfersId =
        cardData?.transfers?.map((transfer) => transfer.shipment_name) || [];
      return [...shipmentsId, ...transfersId];
    }
    return cardData?.parts?.map((part) => part.allocated_shipment_id);
  }, [isActiveBatches, cardData]);

  const batchStatuses = useMemo(() => {
    return isActiveBatches ? extractStatusDetails(cardData?.details) : {};
  }, [isActiveBatches, cardData]);

  const sanitizedCardTitle = cardTitle
    .replace(/\s+/g, "-")
    .replace(/[^a-zA-Z0-9-_]/g, "")
    .replace(/^-+|-+$/g, "");
  const commitButtonId = `commit-links-volumes-button-${sanitizedCardTitle}`;

  const cardRef = useRef(null);
  const isCardActive = Boolean(
    lastSelectedCardRef?.current?.batch_id === cardData?.batch_id ||
      lastSelectedCardRef?.current?.shipment_id === cardData?.shipment_id,
  );

  useEffect(() => {
    let timeoutId;
    if (isCardActive && cardRef.current) {
      setIsOpen(true);
      // Wait for data fetch before scrolling
      timeoutId = setTimeout(() => {
        cardRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 100);
    }
    return () => clearTimeout(timeoutId); // Clear timeout on unmount or isCardActive change
  }, [isCardActive]);

  const batchesToRecall = useMemo(() => {
    if (
      cardData?.parts?.some(
        (part) => part?.shipment_status?.toLowerCase() === "committed",
      )
    ) {
      const filteredParts = cardData?.parts.filter(
        (part) => part?.shipment_status?.toLowerCase() === "committed",
      );

      const batchIds = Array.from(
        new Set(filteredParts.map((part) => part?.batch_id)),
      );

      return batchIds;
    }
    return [];
  }, [cardData]);

  return (
    <>
      <Card
        id="collapsible-card"
        className="mb-4 bg-white"
        data-test="copro-collapsible-card"
      >
        <CardHeader
          className="bg-white border !border-b-0 flex justify-between items-center m-0"
          data-test="copro-collapsible-card-header"
          onClick={() => {
            setIsOpen(!isOpen);
            onCardSelect(cardTitle);
          }}
        >
          <div className="flex flex-row items-center">
            {isActiveBatches ? (
              <BatchChecklistProgress
                popoverId={`popover-${sanitizedCardTitle}`}
                statusDetails={batchStatuses}
              />
            ) : (
              <BatchesStatusChip value={status} small />
            )}

            <span
              data-test="copro-collapsible-card-title"
              id="copro-collapsible-card-title"
              className="fw-normal px-3"
            >
              {cardTitle}
            </span>
            <CopyToClipboard text={cardTitle} />
          </div>
          <div className="flex flex-row items-center">
            {!isActiveBatches && (
              <>
                <UncontrolledDropdown data-test="shipments-actions-dropdown">
                  <DropdownToggle
                    onClick={(e) => e.stopPropagation()}
                    color="tertiary"
                    className="mr-3 rounded-0 border"
                    disabled={!cardData.shipment_type}
                    data-test="shipments-actions-button"
                    id="shipments-actions-button"
                  >
                    <span className="mt-1">{BUTTONS.ACTIONS}</span>
                    <Kebab24 />
                  </DropdownToggle>
                  {getActionsForShipmentType(
                    cardData.shipment_type,
                    setIsOpenBOLModal,
                    setIsOpenSplitModal,
                    cardData.parts?.[0]?.shipment_status,
                    setShowDeletePopover,
                    navigateToDetailsScreen,
                    cardData.parts,
                    toggleResetModal,
                    setIsOpenRecallModal,
                    handleDiscardAction,
                  )}
                </UncontrolledDropdown>
                <ShipmentsDeletePopover
                  showPopover={showDeletePopover}
                  setShowPopover={setShowDeletePopover}
                  refetchShipments={refetchShipments}
                  shipmentID={cardData.shipment_id}
                  discardShipment={handleDiscardAction}
                  isInventoryTransfer={isInventoryTransfer}
                />
                <RecallPopover
                  showPopover={isOpenRecallModal}
                  setShowPopover={setIsOpenRecallModal}
                  batchId={batchesToRecall}
                  refetch={refetchShipments}
                  popoverTarget="shipments-actions-button"
                />
              </>
            )}
            <div>{isOpen ? <Up32 /> : <Down32 />}</div>
          </div>
        </CardHeader>
        <Collapse isOpen={isOpen} innerRef={cardRef}>
          <CardBody className="border" data-test="copro-collapsible-content">
            {Object.values(errors).map((error, index) => (
              <Banner
                key={index}
                errorState={!!error}
                text={error}
                type={BANNER_TYPE.ERROR}
              />
            ))}
            {Object.values(warnings).map((warning, index) => (
              <Banner
                key={index}
                errorState={!!warning}
                text={warning}
                type={BANNER_TYPE.WARNING}
                id={cardId}
              />
            ))}
            {isActiveBatches ? (
              <ActiveBatchCard
                data={cardData}
                handleChange={updateValues}
                handleErrors={updateErrors}
                handleWarnings={updateWarnings}
                handleLoading={setLoading}
                refreshData={refreshData}
                readonly={isReadOnlyCard}
              />
            ) : (
              <ShipmentCard
                data={cardData}
                handleChange={updateValues}
                handleErrors={updateErrors}
                handleLoading={setLoading}
                refreshData={refreshData}
                getBatchTankCertifiedDate={getBatchTankCertifiedDate}
              />
            )}
          </CardBody>
          <CardBody className="border flex justify-end">
            <div>
              <Button
                id={commitButtonId}
                data-test="copro-collapsible-commit-button"
                className="py-2 mr-2 rounded-0 enabled:text-white enabled:bg-black"
                onClick={() => setShowCommitPopover(true)}
                disabled={isReadOnlyCard}
              >
                {isReadOnlyCard ? BUTTONS.COMMITTED : BUTTONS.COMMIT}
              </Button>
              <CommitAllocationPopover
                target={commitButtonId}
                showPopover={showCommitPopover}
                setShowPopover={setShowCommitPopover}
                endingInventory={cardData?.estimated_ending_inventory}
                remainingVolume={cardData?.remaining_volume}
                isActiveBatches={isActiveBatches}
                parts={cardData?.parts}
                refreshData={refreshData}
                commitData={commitData}
                checklistItems={cardData?.details}
                handleErrors={updateErrors}
              />
            </div>
            <Button
              data-test="copro-collapsible-save-button"
              disabled={disableSaveButton}
              className="py-2 rounded-0 enabled:text-gray-800 enabled:bg-white"
              onClick={() => saveValues()}
            >
              {BUTTONS.SAVE}
            </Button>
          </CardBody>
        </Collapse>
      </Card>
      {isOpenBOLModal && (
        <BOLModal
          onClose={() => setIsOpenBOLModal(false)}
          shipmentId={cardTitle}
          shipmentData={cardData}
          onSave={() => {
            setIsOpenBOLModal(false);
            refetchShipments();
          }}
        />
      )}

      <SplitShipmentsModal
        isModalOpen={isOpenSplitModal}
        toggleModal={toggleSplitModal}
        cardData={cardData}
        refetch={refreshData}
      />

      <ResetAllocationModal
        shipmentId={cardData?.shipment_id}
        isModalOpen={isResetModalOpen}
        toggleModal={toggleResetModal}
      />
    </>
  );
};

CollapsibleCard.propTypes = {
  cardType: PropTypes.string,
  cardData: PropTypes.any,
  lastSelectedCardRef: PropTypes.object,
  onCardSelect: PropTypes.func,
  refreshData: PropTypes.func,
  refetchShipments: PropTypes.func,
  getBatchTankCertifiedDate: PropTypes.func,
};

export default CollapsibleCard;
