import {gql} from "@apollo/client";

const ALLOCATION_OUTGOING_OAS_LAYER_FETCH_API = gql`
  query bioLcGetAllocationOASLayerApi(
    $siteReferenceId: String
    $divisionId: String
    $mbLocationGroupId: String
    $mbPeriodStatusId: String
    $mbBalanceGroupId: String
  ) {
    bioLcGetAllocationOASLayerApi(
      event: {
        divisionId: $divisionId
        siteReferenceId: $siteReferenceId
        mbLocationGroupId: $mbLocationGroupId
        mbPeriodStatusId: $mbPeriodStatusId
        mbBalanceGroupId: $mbBalanceGroupId
      }
    ) {
      OASdataList {
        allocationStatus
        destination
        dispatchDate
        documentNumber
        quantityM3
        quantityMT
        tankNumber
        vesselName
        outboundType
        dataChangedFlag
        materialId
        oasLayerId
      }
      error
      statusCode
    }
  }
`;

export default ALLOCATION_OUTGOING_OAS_LAYER_FETCH_API;
