const FEATURE_FLAGS = {
  DOC_MANAGER_1_4: "doc_man_1_4",
  DOC_MANAGER_SPLITS_PERCENTAGE: "doc_man_splits_percentage",
  COMMON_IS_SUPER_USER: "common_is_super_user",
  COMMON_NAVIGATION_REDESIGN: "common_navigation_redesign",
  CONFIG_CENTRE_SHOW_GLOBAL_CONFIG: "config_centre_show_global_config",
  COMP_MNTR_STRO200_dq: "comp_mntr_stro200_dq",
  COPRO_GMB_ACT: "copro_gmb_act",
  SPAIN_COMPLIANCE: "spain_compliance",
  DOC_MAN_DE_SAF_CHANGES: "doc_man_de_saf_changes",
  SAF_GEN_DOC_FLOW_CHANGE: "saf_gen_doc_flow_change",
  COPRO_LTP_NEW_FLOW: "copro_ltp_new_flow",
  LTP_GLOBAL_FILTER: "common_ltp_filter",
  GF_ACTUALIZATION: "gf_actualization",
  GF_MB_SPAIN_TTCC_CHANGES: "gf_mass_balance_spain_ttcc_changes",
  HIDE_SPLITS_UK: "hide_splits_uk",
  ENABLE_DOC_MAN_EDIT: "enable_doc_man_edit",
};

export default FEATURE_FLAGS;
