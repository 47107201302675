import {useLazyQuery} from "@apollo/client";
import {COPRO_US_SHIPMENTS_API_DELETE_SHIPMENT} from "graphql/coprocessing/shipments";
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import {
  DELETE_POPOVER,
  DELETE_SUCCESS,
  ERRORS,
} from "../../constants/shipments";
import DeletePopover from "../Shared/DeletePopover";

const ShipmentsDeletePopover = ({
  showPopover,
  setShowPopover,
  shipmentID,
  refetchShipments,
  discardShipment,
  isInventoryTransfer = false,
}) => {
  const [deleteShipment] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_DELETE_SHIPMENT,
    {
      fetchPolicy: "network-only",
      onCompleted: (result) => {
        if (result?.bioLcCoproUsShipmentsApi?.statusCode === 200) {
          toast.success(DELETE_SUCCESS);
          refetchShipments();
        } else {
          toast.error(ERRORS.FAILED_DELETE);
        }
      },
    },
  );

  const handleDelete = () => {
    deleteShipment({variables: {shipmentId: shipmentID}});
    setShowPopover(false);
  };

  const handleDiscard = () => {
    // Inventory transfers are not deleted from DB but kept in batch_vol_allocation table for
    // incrementing IDs in dsicarded/deleted statuses
    discardShipment();
    setShowPopover(false);
  };

  return (
    <DeletePopover
      handleDelete={isInventoryTransfer ? handleDiscard : handleDelete}
      setShowPopover={setShowPopover}
      showPopover={showPopover}
      popoverTarget="shipments-actions-button"
      header={DELETE_POPOVER.HEADER}
      message={DELETE_POPOVER.MESSAGE}
      cancel={DELETE_POPOVER.CANCEL}
      confirm={DELETE_POPOVER.YES}
      idToDelete={shipmentID}
    />
  );
};

ShipmentsDeletePopover.propTypes = {
  showPopover: PropTypes.bool,
  setShowPopover: PropTypes.func,
  shipmentID: PropTypes.string,
  refetchShipments: PropTypes.func,
  discardShipment: PropTypes.func,
  isInventoryTransfer: PropTypes.bool,
};

export default ShipmentsDeletePopover;
