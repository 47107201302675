import {Filter24} from "@bphxd/ds-core-react/lib/icons";
import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Button} from "reactstrap";

import {useQuery} from "@apollo/client";
import getDivisionCodeMappedValue from "constants/breadcrumbLabels";
import DASHBOARD_LIST_API from "graphql/MassBalance/DashboardListApi";
import MASS_BALANCE_TOP_FILTERS from "graphql/MassBalance/TopFilters";
import {startCase} from "lodash";
import CustomizeTable from "modules/DocManager/components/CustomizeTable/CustomizeTable";
import Layout from "modules/GlobalMassBalance/components/Layout";
import {getMBListColumns} from "modules/GlobalMassBalance/utils";
import {useAppSetting} from "providers/appSetting";
import {useUserSettings} from "providers/userSettings";
import appendParamsToUrl from "utils/helpers/appendParamsToUrl";
import {
  getDivisionData,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting";
import MassBalanceFilterContent from "../MassBalanceFilterContent";
import DocumentTable from "../components/MBListTable";

const MBList = () => {
  const [columnFilter, setColumnFilter] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();
  const {appSetting} = useAppSetting();

  const {country, division} = useParams();

  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const location = searchParams.get("location");
  const period = searchParams.get("period");
  const balance = searchParams.get("balance");

  setCountryDetails(country);
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionCode = division.toUpperCase();
  const divisionData = getDivisionData(divisionCode);

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: getDivisionCodeMappedValue(divisionCode), link: "/"},
    {
      text: startCase(appSetting?.currentCountry?.toLowerCase() ?? ""),
      link: "/",
    },
    {text: "Mass balance"},
  ];

  const [currentFilterValues, setCurrentFilterValues] = useState({});
  const [locationId, setLocationId] = useState();
  const [typeId, setTypeId] = useState();
  const [periodId, setPeriodId] = useState();
  const [balanceId, setBalanceId] = useState();

  const navigate = useNavigate();

  const {data: MBListData, loading: MBListLoading} = useQuery(
    DASHBOARD_LIST_API,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
        mbBalanceTypeId: typeId || type || undefined,
        mbLocationGroupId: locationId || location || undefined,
        mbPeriodStatusId: periodId || undefined,
        mbBalanceGroupId: balanceId || undefined,
      },
      skip:
        siteReferenceData?.siteReferenceId === undefined ||
        divisionData?.divisionId === undefined,
    },
  );

  const {data: mb_period_instances} = useQuery(MASS_BALANCE_TOP_FILTERS, {
    fetchPolicy: "network-only",
    variables: {
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,
  });

  const filterDynamicData = useMemo(
    () => mb_period_instances?.bioLcGetMBFilterOptionsApi?.mbPeriodInstances,
    [mb_period_instances?.bioLcGetMBFilterOptionsApi?.mbPeriodInstances],
  );

  useEffect(() => {
    if (MBListData) {
      setTableData(MBListData?.bioLcGetMassBalanceDashboardCounts?.data);
    }
  }, [MBListData]);

  useEffect(() => {
    if (type) {
      setCurrentFilterValues((prev) => ({...prev, mbBalanceTypeId: type}));
    }
    if (location) {
      setCurrentFilterValues((prev) => ({
        ...prev,
        mbLocationGroupId: location,
      }));
    }
  }, [location, type]);

  const isMBListLoading =
    MBListLoading ||
    siteReferenceData?.siteReferenceId === undefined ||
    divisionData?.divisionId === undefined;

  const tableCols = useMemo(
    () => getMBListColumns(country, divisionCode),
    [country, divisionCode],
  );
  const [columns, setColumns] = useState(tableCols);

  useEffect(() => {
    if (tableCols) {
      setColumns(tableCols);
    }
  }, [tableCols]);

  const filteredColumns = useMemo(
    () => columns.filter(({visible}) => visible),
    [columns],
  );

  const handleFilterSubmit = async (
    mbBalanceTypeId,
    mbLocationGroupId,
    mbPeriodId,
    mbBalanceGroupId,
  ) => {
    if (
      mbLocationGroupId &&
      mbPeriodId &&
      mbBalanceTypeId &&
      mbBalanceGroupId
    ) {
      const period = filterDynamicData.find(
        (item) => item.mbPeriod.mbPeriodId === mbPeriodId,
      )?.mbPeriod?.mbPeriodName;
      const location = filterDynamicData.find(
        (item) => item.mbLocationGroup.mbLocationGroupId === mbLocationGroupId,
      )?.mbLocationGroup?.mbLocationGroupName;
      const balance = filterDynamicData.find(
        (item) => item.mbBalanceGroup.mbBalanceGroupId === mbBalanceGroupId,
      )?.mbBalanceGroup?.mbBalanceGroupName;
      const type = filterDynamicData.find(
        (item) => item.mbBalanceType.mbBalanceTypeId === mbBalanceTypeId,
      )?.mbBalanceType?.mbBalanceTypeCode;

      const redirectUrl = appendParamsToUrl("/mass-balance", [
        country,
        divisionCode,
        type,
        location,
        period,
        balance,
      ]);
      navigate(redirectUrl);
    } else {
      setTypeId(mbBalanceTypeId || undefined);
      setLocationId(mbLocationGroupId || undefined);
      setPeriodId(mbPeriodId || undefined);
      setBalanceId(mbBalanceGroupId || undefined);
    }
  };

  return (
    <Layout title="Mass balance" breadcrumbs={breadcrumbItems}>
      {/* Top Filters */}
      <div className="flex flex-col justify-stretch w-full items-start px-7 pt-[32px]">
        <div className=" flex flex-row justify-between w-full mb-[24px]">
          <div className="flex flex-col justify-stretch w-full items-start">
            <MassBalanceFilterContent
              country={country}
              defaultValues={currentFilterValues}
              filterDynamicData={filterDynamicData}
              handleFilterSubmit={handleFilterSubmit}
              isAllAvailable
            ></MassBalanceFilterContent>
          </div>
        </div>
      </div>
      {/* Customise columns, table, aggregate view */}
      <div className="flex flex-col justify-stretch w-full items-start px-7">
        <div className=" flex flex-row justify-between w-full p-4 bg-white">
          <div>
            <CustomizeTable
              columns={columns}
              onColumnChange={(cols) => setColumns(cols)}
              showApplyButton={false}
            />
          </div>
          <div className="flex flex-none flex-row items-center">
            {columnFilter?.length > 0 && (
              <div className="mr-2">
                <Button
                  data-test="copro-filter-btn"
                  color="standard-tertiary rounded-0"
                  onClick={() => setColumnFilter([])}
                >
                  Clear all
                </Button>
              </div>
            )}
            <div>
              <Button
                className={showFilter ? "border-black" : ""}
                data-test="copro-filter-btn"
                color="standard-tertiary rounded-0"
                onClick={() => setShowFilter(!showFilter)}
              >
                Filters
                <Filter24 className="btn-icon-suffix" />
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full bg-white">
          <DocumentTable
            data={tableData}
            columns={filteredColumns}
            loading={isMBListLoading}
            showFilter={showFilter}
            columnFilter={columnFilter}
            setColumnFilter={setColumnFilter}
          />
        </div>
      </div>
    </Layout>
  );
};

export default MBList;
