import {useQuery} from "@apollo/client";
import {Datepicker} from "@bphxd/ds-core-react";
import ExportBOLButton from "modules/co-processing/components/Historical/ExportBOLButton";
import ExportBtn from "modules/co-processing/components/Historical/ExportBtn";
import {
  ShipmentStatusFilter,
  ShipmentTypeFilter,
} from "modules/co-processing/components/Historical/Filters";
import {HistoricalRefetchProvider} from "modules/co-processing/components/Historical/RefetchHistoricalContext";
import HistoricalTable from "modules/co-processing/components/Historical/Table";
import {getHistoricalConfig} from "modules/co-processing/components/Historical/columnConfig";
import {FORTY_FIVE_DAY_RANGE} from "modules/co-processing/constants/shipments";
import {getDateRange} from "modules/co-processing/helpers/dateHelper";
import {useCallback, useMemo, useState} from "react";
import {useParams} from "react-router-dom";

const HistoricalData = () => {
  const {historicalType} = useParams();
  const config = getHistoricalConfig(historicalType);

  const [dateRange, setDateRange] = useState(
    getDateRange(FORTY_FIVE_DAY_RANGE),
  );
  const [shipmentType, setShipmentType] = useState("");
  const [shipmentStatuses, setShipmentStatuses] = useState([]);

  const handleDateRange = useCallback(([startDate, endDate]) => {
    if (endDate) {
      // End date specified by the user is included in the date range.
      const inclusiveEndDate = new Date(endDate);
      inclusiveEndDate.setDate(endDate.getDate() + 1);
      const dates = [startDate, inclusiveEndDate];
      setDateRange(dates?.map((date) => date.toISOString()));
    }
  }, []);

  const getShipmentsFilters = useCallback(() => {
    const filters = [];
    if (shipmentType) {
      filters.push({key: "shipment_type", values: [shipmentType]});
    }
    if (shipmentStatuses.length > 0) {
      filters.push({key: "shipment_status", values: shipmentStatuses});
    }
    return filters;
  }, [shipmentType, shipmentStatuses]);

  const queryVars = useMemo(() => {
    if (historicalType === "batches") {
      return {
        op: "get_batches",
        batch_tab: "active",
        dateRange,
        includeDetails: true,
        historical: true,
      };
    }
    if (historicalType === "shipments") {
      return {
        offset: -1,
        dateRange,
        historical: true,
        filtersList: getShipmentsFilters(),
      };
    }
    return null;
  }, [dateRange, historicalType, getShipmentsFilters]);

  const {data, loading, refetch} = useQuery(config.apiQuery, {
    fetchPolicy: "network-only",
    variables: queryVars,
  });

  const historicalData = useMemo(
    () => (data && config ? config.transformFn(data) : []),
    [data, config],
  );
  const columns = useMemo(() => config.getColumns(), [config]);

  const truckRackData = useMemo(() => {
    if (historicalType !== "shipments" || !historicalData) return [];
    return historicalData.filter((item) =>
      item.shipment_type?.toLowerCase()?.startsWith("truck"),
    );
  }, [historicalType, historicalData]);

  return (
    <HistoricalRefetchProvider refetch={refetch} loading={loading}>
      <div className="co-processing-container overflow-x-auto">
        <h1 className="pb-6 mx-[40px] fs-2 border-b-[1.5px]">
          {config?.pageTitle}
        </h1>

        <div className="flex flex-col justify-stretch w-full items-start px-7 py-2 mb-8">
          <div className="flex w-full gap-x-4 py-4 pl-5 bg-white justify-between">
            <div className="historical-filters" data-test="historical-filters">
              {historicalType === "shipments" && (
                <>
                  <ShipmentStatusFilter
                    shipmentStatuses={shipmentStatuses}
                    onShipmentStatusChange={setShipmentStatuses}
                  />
                  <ShipmentTypeFilter
                    activeShipmentType={shipmentType}
                    onShipmentTypeChange={setShipmentType}
                  />
                </>
              )}
              <Datepicker
                data-test="historical-date-range-filter"
                placeholder="Custom date range"
                options={{
                  allowInput: false,
                  defaultDate: dateRange,
                  onChange: handleDateRange,
                  mode: "range",
                }}
              />
            </div>
            {historicalType === "shipments" && (
              <ExportBOLButton dateRange={dateRange} />
            )}
            <ExportBtn
              historicalData={historicalData}
              historicalType={historicalType}
            />
          </div>

          <HistoricalTable
            columns={columns}
            data={historicalData}
            loading={loading}
          />
        </div>
      </div>
    </HistoricalRefetchProvider>
  );
};

export default HistoricalData;
