import PropTypes from "prop-types";
import React, {useState} from "react";
import {Popover, PopoverBody} from "reactstrap";

/**
 * SimplePopover is a reusable component that adds hover-triggered popover functionality
 * to any child content. It wraps the target element and manages its own hover state.
 */
const SimplePopover = ({
  children,
  targetId,
  disabled,
  message = "Default message",
  placement = "top",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    if (!disabled) setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div
        id={targetId}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
      <Popover target={targetId} isOpen={isOpen} placement={placement}>
        <PopoverBody className="text-sm text-gray-700">{message}</PopoverBody>
      </Popover>
    </>
  );
};

SimplePopover.propTypes = {
  targetId: PropTypes.string.isRequired,
  message: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  placement: PropTypes.string,
};

export default SimplePopover;
