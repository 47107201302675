import {Trash24} from "@bphxd/ds-core-react/lib/icons";
import BatchUnlinkPopover from "modules/co-processing/components/Shipments/BatchUnlinkPopover";
import MetricInput from "modules/co-processing/components/Shipments/MetricInput";
import {
  ACTIVE_BATCH_KEYS,
  ACTIVE_BATCH_LABELS,
  ACTIVE_BATCH_PLACEHOLDERS,
  BUTTONS,
  WARNINGS,
} from "modules/co-processing/constants/shipments";
import PropTypes from "prop-types";
import {useMemo, useState} from "react";
import {Button} from "reactstrap";
import tw from "twin.macro";
import SimplePopover from "./SimplePopover";

const ActiveBatchLabel = tw.div`min-w-[120px] w-[120px]`;
const ActiveBatchField = tw.div`flex items-center pb-3 text-[13px] font-[400]`;

const ShipmentIdForm = ({
  shipments,
  transfers,
  handleShipmentChange,
  handleErrors,
  handleLoading,
  refreshData,
  batchId,
  remainingVolume,
  updateTotalVolume,
  isReadOnly,
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState(null);

  const getFieldDetails = () => {
    const shipmentKey = ACTIVE_BATCH_KEYS.SHIPMENT_ID;
    return {
      key: shipmentKey,
      dataKey: shipmentKey,
      errorKey: shipmentKey,
      placeholder: ACTIVE_BATCH_PLACEHOLDERS.ID,
    };
  };

  const getSafeId = (shipmentId) => {
    // Replaces whitespace and non-word characters (like #, /, spaces) with hyphens
    return `copro-us-unlink-shipment-${shipmentId?.replace(/[\s\W]+/g, "-")}`;
  };

  const onRemove = (event, shipment) => {
    event.stopPropagation();
    setSelectedShipment({
      name: shipment.shipment_id,
      id: shipment.batch_allocation_id,
    });
    setShowPopover(true);
  };

  const sortedShipments = useMemo(() => {
    const normalizedShipments = shipments.map((shipment) => ({
      ...shipment,
      type: "shipment",
      shipment_date: shipment.shipment_date,
    }));

    const normalizedTransfers = !Array.isArray(transfers)
      ? []
      : transfers
          .map((transfer) => ({
            ...transfer,
            type: "transfer",
            shipment_id: transfer.shipment_name,
            shipment_date: transfer.shipment_start_date,
            batch_allocation_id: null,
          }))
          .filter((transfer) => transfer.source_batch_code === batchId);

    return [...normalizedShipments, ...normalizedTransfers].sort((a, b) => {
      if (!a.shipment_date) return 1;
      if (!b.shipment_date) return -1;

      const dateA = new Date(a.shipment_date).getTime();
      const dateB = new Date(b.shipment_date).getTime();

      if (!dateA && dateB) return 1;
      if (dateA && !dateB) return -1;

      return dateA - dateB;
    });
  }, [shipments, transfers, batchId]);

  return (
    <div className="w-full md:w-1/2 px-2">
      {sortedShipments?.map((shipment, index) => (
        <div data-test="copro-active-batch-shipment-list" key={index}>
          <div
            data-test="copro-active-batch-shipment-title"
            className="text-[13px] fw-medium pb-2 flex flex-row justify-between items-center"
          >
            <div>{`${ACTIVE_BATCH_LABELS.SHIPMENT} ${index + 1}`}</div>
            {!!shipment?.shipment_id && (
              <SimplePopover
                targetId={getSafeId(shipment?.shipment_id)}
                message={WARNINGS.NO_DELETE_INV_SHIPMENT}
                disabled={shipment.type !== "transfer"}
              >
                <Button
                  data-test="copro-us-delete-shipment-btn"
                  disabled={isReadOnly || shipment.type === "transfer"}
                  onClick={(event) => onRemove(event, shipment)}
                  className={`ml-2 bg-white border-0 text-black ${
                    shipment.type === "transfer" ? "fw-light" : "fw-medium"
                  }`}
                >
                  <span>{BUTTONS.DELETE}</span>
                  <Trash24
                    className={`!text-black ${
                      shipment.type === "transfer"
                        ? "cursor-no-drop"
                        : "cursor-pointer"
                    }`}
                    color="black"
                    disabled={isReadOnly || shipment.type === "transfer"}
                  />
                </Button>
              </SimplePopover>
            )}
          </div>

          {shipment?.shipment_id ? (
            <>
              <ActiveBatchField>
                <ActiveBatchLabel data-test="copro-active-batch-shipment-fields">
                  {ACTIVE_BATCH_LABELS.SHIPMENT_VOLUME}
                </ActiveBatchLabel>
                <div>{`${shipment?.shipment_volume?.toLocaleString()} bbl`}</div>
              </ActiveBatchField>

              <ActiveBatchField data-test="copro-active-batch-shipment-inputs">
                <ActiveBatchLabel>
                  {ACTIVE_BATCH_LABELS.SHIPMENT_ID}
                </ActiveBatchLabel>
                <div className="flex items-center">
                  <div className="flex-1">{shipment?.shipment_id}</div>
                </div>
              </ActiveBatchField>
            </>
          ) : (
            <ActiveBatchField data-test="copro-active-batch-shipment-inputs">
              <ActiveBatchLabel>
                {ACTIVE_BATCH_LABELS.SHIPMENT_ID}
              </ActiveBatchLabel>
              <MetricInput
                batchId={batchId}
                value={shipment?.shipment_id}
                details={getFieldDetails(index)}
                onChange={(event) =>
                  handleShipmentChange(event.target.value, index)
                }
                reportErrors={handleErrors}
                setChecking={handleLoading}
                remainingVolume={remainingVolume}
                updateTotalVolume={updateTotalVolume}
              />
            </ActiveBatchField>
          )}
        </div>
      ))}
      {!!selectedShipment?.id && (
        <BatchUnlinkPopover
          showPopover={showPopover}
          setShowPopover={setShowPopover}
          popoverID={getSafeId(selectedShipment?.name)}
          refreshData={refreshData}
          shipmentName={selectedShipment?.name}
          shipmentID={selectedShipment?.id}
        />
      )}
    </div>
  );
};

ShipmentIdForm.propTypes = {
  shipments: PropTypes.array,
  transfers: PropTypes.array,
  handleShipmentChange: PropTypes.func,
  handleErrors: PropTypes.func,
  handleLoading: PropTypes.func,
  refreshData: PropTypes.func,
  batchId: PropTypes.string,
  remainingVolume: PropTypes.number,
  updateTotalVolume: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

export default ShipmentIdForm;
