import {Down24, Kebab24, Up24} from "@bphxd/ds-core-react/lib/icons";
import {documentStatus} from "constants/docManager";
import {
  RejectionMessages,
  docStatusClassNames,
  docStatusMessages,
} from "content/DocStatus";
import moment from "moment";
import PropTypes from "prop-types";
import {useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import appendParamsToUrl from "utils/helpers/appendParamsToUrl";
import {getFormattedDecimal} from "utils/numberUtil";
import HighLightedText from "../../HighLightedText";
import StatusChip from "../../StatusChip";
import EditModal from "./EditModal";
import VersionModal from "./VersionModal";
import "./index.css";

const RenderLink = (
  id,
  text,
  pageNo,
  cell,
  selectedCountry,
  siteReferenceId,
  divisionId,
) => {
  const {country} = useParams();
  return (
    <Link
      to={{
        pathname: `/document-manager/incoming/${selectedCountry}/detailedView/${id}`,
        search: `?pageNo=${pageNo}&siteReferenceId=${siteReferenceId}&divisionId=${divisionId}`,
      }}
      className="link-dark whitespace-nowrap"
    >
      <u>
        <HighLightedText
          value={text}
          globalFilter={cell.getContext().table.getState().globalFilter}
        />
      </u>
    </Link>
  );
};

const RenderDropdown = ({
  certificateInboundEuId,
  selectedCountry,
  pageNo,
  siteReferenceId,
  divisionId,
  previousVersions,
  previousVersionsCount,
  ENABLE_DOC_MAN_EDIT,
  state,
  editFlag,
}) => {
  const [versionModal, setVersionModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const navigate = useNavigate();
  const {country} = useParams();
  console.log("editFlag", editFlag);
  return (
    <>
      <VersionModal
        versionModal={versionModal}
        setVersionModal={setVersionModal}
        previousVersions={previousVersions}
        selectedCountry={selectedCountry}
        pageNo={pageNo}
        siteReferenceId={siteReferenceId}
        divisionId={divisionId}
        previousVersionsCount={previousVersionsCount}
        certificateInboundEuId={certificateInboundEuId}
      />
      <EditModal
        editModal={editModal}
        setEditModal={setEditModal}
        certificateInboundEuId={certificateInboundEuId}
        selectedCountry={selectedCountry}
        pageNo={pageNo}
        siteReferenceId={siteReferenceId}
        divisionId={divisionId}
      />
      <UncontrolledDropdown
        direction="down"
        className="doc-manager-action-dropdown"
      >
        <DropdownToggle
          color="standard-quartenary"
          className="!px-0 doc-manager-action-dropdown-button"
        >
          <Kebab24></Kebab24>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu" container="body">
          <DropdownItem
            onClick={() =>
              navigate({
                pathname: `/document-manager/incoming/${selectedCountry}/detailedView/${encodeURIComponent(
                  certificateInboundEuId,
                )}`,
                search: `?pageNo=${pageNo}&siteReferenceId=${siteReferenceId}&divisionId=${divisionId}`,
              })
            }
          >
            View document
          </DropdownItem>
          {ENABLE_DOC_MAN_EDIT && (
            <DropdownItem
              onClick={() => setEditModal(true)}
              disabled={
                (state !== "ready_to_assign" && state !== "split") ||
                (state === "split" && !editFlag)
              }
            >
              Edit document
            </DropdownItem>
          )}
          <DropdownItem
            onClick={() => setVersionModal(true)}
            disabled={
              previousVersionsCount === null || previousVersionsCount === 0
            }
          >
            Version history ({previousVersionsCount ?? 0})
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

RenderDropdown.propTypes = {
  certificateInboundEuId: PropTypes.string,
  selectedCountry: PropTypes.string,
  pageNo: PropTypes.number,
  siteReferenceId: PropTypes.string,
  divisionId: PropTypes.string,
  previousVersions: PropTypes.array,
  previousVersionsCount: PropTypes.number,
  ENABLE_DOC_MAN_EDIT: PropTypes.bool,
  state: PropTypes.string,
  editFlag: PropTypes.any,
};

const renderStatus = (text, cell) => {
  const defaultClassName = docStatusClassNames["Failed to process"];
  const className = docStatusClassNames[text];

  return (
    <StatusChip
      className={className || defaultClassName}
      message={text}
      globalFilter={cell.getContext().table.getState().globalFilter}
    />
  );
};

const getColumnsUK = (
  dateFormat,
  decimalFormatNew,
  selectedCountry,
  siteReferenceId,
  divisionId,
  divisionCode,
  ENABLE_DOC_MAN_EDIT,
) => {
  return [
    {
      header: "",
      key: "expandCollapse",
      accessorKey: "splitDetails",
      visible: true,
      enableSorting: false,
      size: 20,
      cell: ({row}) => {
        return row.getCanExpand() ? (
          <Button
            onClick={row.getToggleExpandedHandler()}
            color="standard-quartenary"
            className="!px-0"
          >
            {row.getIsExpanded() ? <Up24 /> : <Down24 />}
          </Button>
        ) : (
          ""
        );
      },
    },
    {
      header: "Document",
      accessorKey: "sdNumber",
      key: "sdNumber",
      cell: ({row, getValue, table, cell}) => {
        return RenderLink(
          encodeURIComponent(row.original.certificateInboundEuId),
          row?.original?.docNumberSplit === null
            ? row?.original?.sdNumber
            : row?.original?.docNumberSplit,
          table.getState()?.pagination?.pageIndex,
          cell,
          selectedCountry,
          siteReferenceId,
          divisionId,
        );
      },
      size: 250,
      maxSize: 400,
      visible: true,
    },
    {
      header: "Supplier",
      accessorKey: "supplierName",
      key: "supplierName",
      size: 150,
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Receiving point",
      accessorKey: "recipientReceiptAddress",
      key: "recipientReceiptAddress",
      size: 200,
      visible: true,
      cell: ({cell, row}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Product",
      accessorKey: "productType",
      key: "productType",
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Raw material",
      accessorKey: "rawMaterialType",
      key: "rawMaterialType",
      size: 110,
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Quantity m³",
      accessorKey: "feedstockQty",
      key: "feedstockQty",
      size: 100,
      visible: true,
      dataType: "number",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell, 3)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Mass balance",
      accessorKey: "balance",
      key: "balance",
      size: 200,
      visible: true,
      accessorFn: ({mbPeriodName, mbLocationGroupName, state}) => {
        return (
          state === documentStatus.COMPLETED &&
          `${mbPeriodName} - ${mbLocationGroupName}`
        );
      },
      cell: ({
        row: {
          original: {
            mbBalanceGroupId,
            mbBalanceGroupName,
            mbLocationGroupId,
            mbLocationGroupName,
            mbPeriodName,
            mbPeriodId,
            mbPeriodFrom,
            mbPeriodTo,
            state,
          },
        },
        cell,
      }) =>
        state === documentStatus.COMPLETED &&
        mbLocationGroupName &&
        mbPeriodName ? (
          <Link
            to={appendParamsToUrl("/mass-balance", [
              selectedCountry,
              divisionCode,
              "TRS",
              mbLocationGroupName,
              mbPeriodName,
              mbBalanceGroupName,
            ])}
            className="link-dark"
            state={{mbPeriodId}}
          >
            <u>{cell.getValue()}</u>
          </Link>
        ) : (
          ""
        ),
    },
    {
      header: "Dispatch address",
      accessorKey: "supplierDispatchAddress",
      key: "supplierDispatchAddress",
      size: 200,
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Issuance date",
      accessorKey: "feedstockIssueDatez",
      key: "feedstockIssueDatez",
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={
              cell.getValue() && moment(cell.getValue()).format(dateFormat)
            }
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Upload date",
      accessorKey: "bvAuditCreatedDatez",
      key: "bvAuditCreatedDatez",
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={
              cell.getValue() && moment(cell.getValue()).format(dateFormat)
            }
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Additional information",
      accessorKey: "additionalInfo",
      key: "additionalInfo",
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Country of origin",
      accessorKey: "countryCode",
      key: "countryCode",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "GHG savings %",
      accessorKey: "ghgEmissionPercentageIn",
      key: "ghgEmissionPercentageIn",
      size: 110,
      visible: false,
      dataType: "number",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell, 2)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "GHG total gCO2eq/MJ",
      accessorKey: "ghgTotal",
      key: "ghgTotal",
      size: 110,
      visible: false,
      dataType: "number",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell, 3)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Scheme",
      accessorKey: "certificationSystem",
      key: "certificationSystem",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Dispatch date",
      accessorKey: "materialDispatchDatez",
      key: "materialDispatchDatez",
      size: 200,
      visible: false,
      filterFn: "dateRangeFilterFn",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={
              cell.getValue() && moment(cell.getValue()).format(dateFormat)
            }
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Contract number",
      accessorKey: "contractNumber",
      key: "contractNumber",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    // This will be used later
    // {
    //   header: "Document type",
    //   accessorKey: "documentType",
    //   key: "documentType",
    //   size: 200,
    //   visible: false,
    //   cell: ({cell}) => {
    //     return (
    //       <HighLightedText
    //         value={cell.getValue()}
    //         globalFilter={cell.getContext().table.getState().globalFilter}
    //       />
    //     );
    //   },
    // },
    {
      header: "Reason for rejection",
      accessorKey: "detailedState",
      key: "detailedState",
      size: 200,
      visible: false,
      accessorFn: ({detailedState}) => {
        const defaultMessage = "";
        const message = RejectionMessages[detailedState];
        return message || defaultMessage;
      },
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Status",
      key: "state",
      disabled: true,
      accessorKey: "state",
      visible: true,
      draggaingDisabled: true,
      accessorFn: ({state}) => {
        const defaultMessage = docStatusMessages.failed_to_process;
        const message = docStatusMessages[state];
        return message || defaultMessage;
      },
      cell: ({getValue, cell}) => renderStatus(getValue(), cell),
    },
    {
      header: "",
      accessorKey: "actions",
      key: "actions",
      size: 25,
      visible: true,
      enableSorting: false,
      cell: ({
        row: {
          original: {
            certificateInboundEuId,
            versionHistoryData,
            versionCount,
            state,
            splitDetails,
            editFlag,
          },
        },
        table,
      }) => (
        <RenderDropdown
          certificateInboundEuId={certificateInboundEuId}
          selectedCountry={selectedCountry}
          pageNo={table.getState()?.pagination?.pageIndex}
          siteReferenceId={siteReferenceId}
          divisionId={divisionId}
          previousVersions={versionHistoryData}
          previousVersionsCount={versionCount}
          ENABLE_DOC_MAN_EDIT={ENABLE_DOC_MAN_EDIT}
          editFlag={editFlag}
        />
      ),
    },
  ];
};

export default getColumnsUK;
