import {NUMBER_OF_DECIMALS} from "constants/common";
import moment from "moment";
import {getFormattedNumberWithCheck} from "utils/numberUtil";

function parseDeliveryDate(deliveryDate) {
  const dateArr = deliveryDate?.split("-");
  const year = dateArr[0];
  const month = dateArr[1];
  const day = 1;
  const date = new Date(year, month - 1, day);
  return date;
}

const getOutgoingColumnsGfTrsSpain = (
  dateFormat,
  decimalFormat,
  country,
  siteReferenceId,
  divisionId,
) => [
  {
    header: "Outgoing",
    columns: [
      {
        header: "Dispatch date",
        accessorKey: "dispatchDate",
        key: "dispatchDate",
        visible: true,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFn",
        accessorFn: (row) => {
          return parseDeliveryDate(row?.dispatchDate);
        },
        cell: ({cell}) => {
          const month = moment(cell.getValue()).format("MMMM");
          const year = moment(cell.getValue()).format("YYYY");
          return cell.getValue() && `${month} ${year}`;
        },
      },
      {
        header: "Outbound type",
        accessorKey: "outboundType",
        key: "outboundType",
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Vessel name",
        accessorKey: "vesselName",
        key: "vesselName",
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Destination",
        accessorKey: "destination",
        key: "destination",
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Sustainable quantity m³",
        accessorKey: "quantityM3",
        key: "quantityM3",
        visible: true,
        dataType: "number",
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
        accessorFn: (row) => {
          return row?.quantityM3?.toString();
        },
      },
    ],
  },
];

export default getOutgoingColumnsGfTrsSpain;
