import {useAccount, useMsal} from "@azure/msal-react";
import {Close, Datepicker} from "@bphxd/ds-core-react";
import {COUNTRY_SPAIN, COUNTRY_UK} from "constants/countryDetails";
import {DIV_CODE_GF} from "constants/divisionDetails";
import {RetireForMandateAPI} from "graphql/MassBalance/Actions/Retireformandate";
import {getQtyandUom} from "modules/GlobalMassBalance/utils";
import PropTypes from "prop-types";
import {useAppSetting} from "providers/appSetting/context.js";
import {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import {getDivisionData, getSiteDetails} from "utils/helpers/getAppSetting.js";
import "../index.css";

const RetireForMandate = ({
  isVisibleModal,
  setModal,
  rowData,
  divisionCode,
}) => {
  const {appSetting} = useAppSetting();
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(divisionCode);

  const {country, period} = useParams();

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const [isModalVisible, setModalVisible] = useState(isVisibleModal);
  const [isProcessing, setProcessing] = useState(false);

  useEffect(() => {
    setModalVisible(isVisibleModal);
  }, [isVisibleModal]);

  const methods = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });

  const {
    register,
    formState: {errors},
    setValue,
    getValues,
    trigger,
    watch,
  } = methods;

  const watchQuantity = parseFloat(watch("quantity"));
  const watchDate = parseFloat(watch("dateofRetire"));

  const onSubmit = async () => {
    const valid = await trigger();
    const {...docData} = getValues();
    if (valid) {
      setProcessing(true);
      const {data: response} = await RetireForMandateAPI({
        reason: docData?.notes || "",
        dateofRetire: docData?.dateofRetire || "",
        mbInboundQtyEuId: rowData.original.inboundRecord.mbInboundQtyEuId,
        quantity: docData.quantity,
        createdBy: account.username,
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
      });

      if (response?.bioLcGlobalMassBalanceDiscardQuantity?.statusCode === 200) {
        setProcessing(false);
        setModalVisible(false);
        setModal("");
        toast.success(
          response?.bioLcGlobalMassBalanceDiscardQuantity?.message ??
            "Retire for Mandate was successfully added",
        );
      } else {
        setProcessing(false);
        toast.error(
          response?.bioLcGlobalMassBalanceDiscardQuantity?.error ??
            "Something went wrong, failed to retire to mandate",
        );
      }
    }
  };

  const QUARTER_DATE_RANGE = {
    Q1: {start: "01-01", end: "03-31"}, // January to March
    Q2: {start: "04-01", end: "06-30"}, // April to June
    Q3: {start: "07-01", end: "09-30"}, // July to September
    Q4: {start: "10-01", end: "12-31"}, // October to December
  };

  // Utility to normalize a date to midnight (00:00:00)
  const normalizeDate = (date) => {
    const normalized = new Date(date);
    normalized.setHours(0, 0, 0, 0);
    return normalized;
  };

  const getQuarterDateRange = (quarter) => {
    const [qtr, year] = quarter.split(" ");
    const range = QUARTER_DATE_RANGE[qtr];
    if (!range) {
      throw new Error(`Invalid quarter: ${quarter}`);
    }
    return {
      startDate: normalizeDate(new Date(`${year}-${range.start}`)),
      endDate: normalizeDate(new Date(`${year}-${range.end}`)),
    };
  };

  const mbPeriod = decodeURIComponent(period);
  const {startDate, endDate} = getQuarterDateRange(mbPeriod);

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const {quantity, quantity_uom} = getQtyandUom(rowData, divisionCode);

  const RETIRE_TO_MANDATE_HEADER_LABEL =
    COUNTRY_SPAIN === country && DIV_CODE_GF === divisionCode
      ? "Retire for mandate"
      : "Retire for mandate opt in";
  const RETIRE_TO_MANDATE_QUANTITY_LABEL =
    COUNTRY_SPAIN === country && DIV_CODE_GF === divisionCode
      ? "Quantity m³"
      : "Sustainable quantity m³";

  const notes_mandatory_rules =
    COUNTRY_UK === country ? {} : {required: "Please enter notes"};

  return (
    <div>
      <Modal
        size="sm"
        isOpen={isModalVisible}
        className="modal-dialog-centered mb-action-modal-344"
      >
        <ModalHeader
          className="!border-b-[1px] !border-gray-200 mb-5 text-xl"
          close={
            <Close
              onClick={() => {
                setModalVisible(false);
                setModal("");
              }}
            />
          }
        >
          {RETIRE_TO_MANDATE_HEADER_LABEL}
        </ModalHeader>
        <ModalBody className="text-center pt-0">
          <FormProvider {...methods}>
            <Form className="flex flex-col gap-5 text-left">
              <FormGroup>
                <Label for="quantity" className="fw-light">
                  {RETIRE_TO_MANDATE_QUANTITY_LABEL}
                </Label>
                <Input
                  type="number"
                  id="quantity"
                  {...computeProps("quantity", {
                    validate: (value) =>
                      value > 0 || "The quantity must be greater than zero",
                    required: "Please enter quantity",
                  })}
                  invalid={!!errors.quantity}
                  maxLength={100}
                />
                {errors.quantity && (
                  <FormFeedback>{errors.quantity.message}</FormFeedback>
                )}
                {!errors.quantity && watchQuantity > quantity && (
                  <FormFeedback className="!block">
                    It cannot exceed the maximum quantity
                  </FormFeedback>
                )}
                <span className="text-xs text-gray-700">{`You have ${quantity} ${quantity_uom} available`}</span>
              </FormGroup>
              {COUNTRY_SPAIN === country &&
              DIV_CODE_GF === divisionData?.divisionCode ? (
                <FormGroup>
                  <Label for="dateofRetire" className="fw-light">
                    Date of Retire
                  </Label>
                  <Datepicker
                    id="dateofRetire"
                    type="date"
                    className="bp-datepicker-bp-core !placeholder-[#111111a3]"
                    placeholder="Select date"
                    invalid={!!errors.dateofRetire}
                    options={{
                      allowInput: false,
                      dateFormat: "d/m/Y",
                      enableTime: false,
                      mode: "single",
                      enable: [
                        {
                          from: startDate,
                          to: endDate,
                        },
                      ],
                      onClose: (selectedDates) => {
                        if (selectedDates.length > 0) {
                          const selectedDate = new Date(selectedDates[0]);
                          const formattedDate = selectedDate
                            .toLocaleDateString("en-GB")
                            .split("/")
                            .reverse()
                            .join("-");
                          setValue("dateofRetire", formattedDate);
                        } else {
                          setValue("dateofRetire", null);
                        }
                      },
                    }}
                  />
                  {errors.dateofRetire && (
                    <FormFeedback>{errors.dateofRetire.message}</FormFeedback>
                  )}
                </FormGroup>
              ) : (
                <FormGroup>
                  <Label for="notes" className="fw-normal mb-4">
                    Notes
                  </Label>

                  <Input
                    type="textarea"
                    rows="3"
                    id="notes"
                    data-test="notes"
                    {...computeProps("notes", notes_mandatory_rules)}
                    invalid={!!errors.notes}
                    maxLength={200}
                  />
                  {errors.notes && (
                    <FormFeedback>{errors.notes.message}</FormFeedback>
                  )}
                </FormGroup>
              )}
            </Form>
          </FormProvider>
        </ModalBody>
        <ModalFooter className="p-0 d-block">
          <div className="row g-0 m-0 modal-footer-row">
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
                onClick={() => {
                  setModalVisible(false);
                  setModal("");
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-4 bg-transparent text-default whitespace-nowrap !p-0"
                type="button"
                disabled={
                  isProcessing ||
                  errors.quantity ||
                  !watchQuantity ||
                  watchQuantity > quantity ||
                  (COUNTRY_SPAIN === country &&
                    DIV_CODE_GF === divisionCode &&
                    !watchDate)
                }
                onClick={onSubmit}
              >
                {isProcessing && (
                  <Spinner size="sm" className="btn-icon-prefix" />
                )}
                Retire for mandate
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

RetireForMandate.propTypes = {
  isVisibleModal: PropTypes.bool,
  setModal: PropTypes.func,
  rowData: PropTypes.object,
  divisionCode: PropTypes.string,
};

export default RetireForMandate;
