import {
  Check24,
  Left24,
  Save24,
  SpinnerCircle24,
  Upload24,
} from "@bphxd/ds-core-react/lib/icons";
import {isEqual} from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import {useAppSetting} from "providers/appSetting";
import {useUserSettings} from "providers/userSettings";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import "../index.css";

const HeaderSection = ({
  onSave,
  onPublish,
  isSaving,
  isPublishing,
  versionDetailsData,
  selectedCountry,
  selectedTemplateItems,
  initialTemplateItems,
  isTesting,
  setIsTesting,
  isDisabled,
  setIndexForExecute,
}) => {
  const {appSetting, setAppSetting} = useAppSetting();
  const [backtoVersionsModal, setBacktoVersionModal] = useState(false);
  const navigate = useNavigate();
  const [changesFlag, setChangesFlag] = useState(false);

  const {
    userSettings: {dateFormat},
  } = useUserSettings();

  const versionDetails =
    versionDetailsData &&
    versionDetailsData.versionDetails?.length > 0 &&
    versionDetailsData.versionDetails[0];

  const ruleDetails =
    versionDetailsData &&
    versionDetailsData.ruleDetails?.length > 0 &&
    versionDetailsData?.ruleDetails[0];

  const versionBlocks = versionDetailsData && versionDetailsData?.versionBlocks;

  const backtoVersionsURL = `/compliance-rules-engine/versions/${ruleDetails?.ruleMasterId}`;

  useEffect(() => {
    if (!isEqual(selectedTemplateItems, initialTemplateItems)) {
      setChangesFlag(true);
    } else {
      setChangesFlag(false);
    }
  }, [selectedTemplateItems, initialTemplateItems]);

  const handleBacktoVersions = () => {
    if (changesFlag && versionDetails?.status !== "PUBLISHED") {
      setBacktoVersionModal(true);
    } else {
      navigate(backtoVersionsURL);
    }
  };

  const testButtonText = isTesting ? "Return to edit mode" : "Test";

  const saveButtontext = changesFlag ? "Save" : "Saved";

  const saveButtonLabel = isSaving ? "Saving" : saveButtontext;

  const PublishButtontext =
    versionDetails?.status === "PUBLISHED" ? "Published" : "Publish";

  const PublishButtonLabel = isPublishing ? "Publishing" : PublishButtontext;

  return (
    <div className="h-[72px] p-4 w-full flex flex-row justify-between bg-[#fff] !border-b-[1px] !border-gray-200">
      <div className="flex items-center">
        <span
          className="flex flex-row items-center w-full justify-start cursor-pointer"
          onClick={() => handleBacktoVersions()}
          onKeyDown={() => handleBacktoVersions()}
        >
          <Left24 />
          <span className="pl-3.5 ">Back to versions</span>
        </span>
      </div>
      <div className="flex flex-row items-center gap-x-8">
        <h6 className="!m-0 normal-case">{versionDetails?.status}</h6>
        <h6 className="!m-0 normal-case font-normal fw-normal rule-editor-title-label">
          {ruleDetails?.ruleName}
        </h6>
        <h6 className="!m-0 normal-case">{`Version - ${versionDetails?.version}`}</h6>
        <h6 className="!m-0 normal-case ">{`Valid from: ${moment(
          versionDetails?.validFrom,
          "YYYY-MM-DD",
        ).format(dateFormat)}`}</h6>
      </div>
      <div className="flex items-center gap-x-3">
        <Button
          type="button"
          color="standard-tertiary bg-white"
          className="rounded-0 btn-standard-secondary bg-white"
          onClick={() => {
            setIsTesting(!isTesting);
            setIndexForExecute(0);
          }}
          disabled={
            isSaving ||
            changesFlag ||
            versionBlocks?.length === 0 ||
            versionDetails?.status === "DELETED"
          }
        >
          {testButtonText}
        </Button>
        <Button
          type="button"
          color="standard-tertiary"
          className="show btn-bg-br-gray btn btn-tertiary rounded-0"
          onClick={() => onSave()}
          onKeyDown={() => onSave()}
          disabled={
            isSaving ||
            !changesFlag ||
            versionDetails?.status === "PUBLISHED" ||
            isDisabled
          }
        >
          {saveButtonLabel}
          {isSaving ? (
            <SpinnerCircle24 className="btn-icon-suffix" />
          ) : (
            <>
              {changesFlag && <Save24 className="btn-icon-suffix" />}
              {!changesFlag && versionDetails?.status === "DRAFT" && (
                <Check24 className="btn-icon-suffix" />
              )}
            </>
          )}
        </Button>
        <Button
          type="button"
          color="standard-primary"
          className="primary-btn rounded-0"
          onClick={() => onPublish(false)}
          onKeyDown={() => onPublish(false)}
          disabled={
            isPublishing ||
            changesFlag ||
            versionDetails?.status === "PUBLISHED" ||
            changesFlag ||
            versionBlocks?.length === 0 ||
            isDisabled
          }
        >
          {PublishButtonLabel}
          {isPublishing ? (
            <SpinnerCircle24 className="btn-icon-suffix" />
          ) : (
            <Upload24 className="btn-icon-suffix" />
          )}
        </Button>
      </div>

      <Modal
        size="sm"
        isOpen={backtoVersionsModal}
        className="modal-dialog-centered"
      >
        <ModalBody className="text-center">
          <p className="mb-[2px]"></p>
          <p className=" mb-0">Unsaved changes will be lost if you go back</p>
        </ModalBody>
        <ModalFooter className="p-0 d-block">
          <div className="row g-0 m-0 modal-footer-row">
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 !py-[10px] opacity-80 opacity-100-hover bg-transparent"
                onClick={() => {
                  setBacktoVersionModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 !py-[10px] bg-transparent text-default"
                onClick={() => navigate(backtoVersionsURL)}
                onKeyDown={() => navigate(backtoVersionsURL)}
              >
                Go back
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

HeaderSection.propTypes = {
  onSave: PropTypes.func,
  onPublish: PropTypes.func,
  isSaving: PropTypes.bool,
  isPublishing: PropTypes.bool,
  versionDetailsData: PropTypes.object,
  selectedCountry: PropTypes.string,
  initialTemplateItems: PropTypes.array,
  selectedTemplateItems: PropTypes.array,
  isTesting: PropTypes.bool,
  setIsTesting: PropTypes.func,
  isDisabled: PropTypes.bool,
  setIndexForExecute: PropTypes.func,
};
export default HeaderSection;
