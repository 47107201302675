import {gql} from "@apollo/client";

const ALLOCATION_OUTGOING_FETCH_API = gql`
  query bioLcGetAllocationSalesApi(
    $siteReferenceId: String
    $divisionId: String
    $mbBalanceTypeId: String
    $mbLocationGroupId: String
    $mbPeriodStatusId: String
    $mbBalanceGroupId: String
  ) {
    bioLcGetAllocationSalesApi(
      event: {
        divisionId: $divisionId
        siteReferenceId: $siteReferenceId
        mbBalanceTypeId: $mbBalanceTypeId
        mbLocationGroupId: $mbLocationGroupId
        mbPeriodStatusId: $mbPeriodStatusId
        mbBalanceGroupId: $mbBalanceGroupId
      }
    ) {
      statusCode
      error
      documents {
        deliveryDate
        outboundType
        recipient
        sustainableQtyM3
        fuelQtyM3
        shipToLocation
        dispatchLocation
      }
    }
  }
`;

const SPAIN_TTCC_ALLOCATION_OUTGOING_FETCH_API = gql`
  query bioLcGetAllocationOASLayerApi(
    $siteReferenceId: String
    $divisionId: String
    $mbLocationGroupId: String
    $mbPeriodStatusId: String
    $mbBalanceGroupId: String
  ) {
    bioLcGetAllocationOASLayerApi(
      event: {
        divisionId: $divisionId
        siteReferenceId: $siteReferenceId
        mbLocationGroupId: $mbLocationGroupId
        mbPeriodStatusId: $mbPeriodStatusId
        mbBalanceGroupId: $mbBalanceGroupId
      }
    ) {
      statusCode
      error
      OASdataList {
        allocationStatus
        destination
        dispatchDate
        documentNumber
        outboundType
        quantityM3
        quantityMT
        tankNumber
        vesselName
      }
    }
  }
`;

export {
  ALLOCATION_OUTGOING_FETCH_API,
  SPAIN_TTCC_ALLOCATION_OUTGOING_FETCH_API,
};
