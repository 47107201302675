const valueMap = {
  GF: "Ground Fuels",
  COPRO: "Co-processing",
  SAF: "SAF",
};

export const getDivisionCodeMappedValue = (key) => {
  return valueMap[key] || "";
};

export default getDivisionCodeMappedValue;
