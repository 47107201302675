import {Close} from "@bphxd/ds-core-react";
import {Flag24} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";

import {Modal, ModalBody, ModalHeader} from "reactstrap";

const VersionModal = ({
  versionModal,
  setVersionModal,
  previousVersions,

  selectedCountry,
  pageNo,
  siteReferenceId,
  divisionId,

  previousVersionsCount,
  certificateInboundEuId,
}) => {
  const navigate = useNavigate();

  return (
    <Modal size="sm" isOpen={versionModal} className="modal-dialog-centered">
      <ModalHeader
        className="p-4"
        style={{
          borderBottom: "solid 1px #80808061",
        }}
        close={
          <Close
            onClick={() => {
              setVersionModal(false);
            }}
          />
        }
      >
        Version history
      </ModalHeader>
      <ModalBody
        style={{
          padding: "0px",
        }}
      >
        {previousVersions?.map((prevItem) => (
          <div
            className="flex p-3.5 border-b border-gray-400 cursor-pointer"
            style={{color: "gray"}}
            key={prevItem.linkedCert}
          >
            <div className="mr-1.5">
              <Flag24 color="grey" />
            </div>
            <div
              className="max-w-[calc(100%-38px)]"
              onClick={() =>
                navigate({
                  pathname: `/document-manager/incoming/${selectedCountry}/detailedView/${encodeURIComponent(
                    prevItem.linkedCert,
                  )}`,
                  search: `?pageNo=${pageNo}&siteReferenceId=${siteReferenceId}&divisionId=${divisionId}`,
                })
              }
              // This will be removed in future commits
              onKeyDown={() => {}}
            >
              <p className="text-sm mb-0.5 break-words">{prevItem.sdNumber}</p>
              <p className="text-xs mb-0">
                Rejected {prevItem.bvAuditChangedDatez}
              </p>
            </div>
          </div>
        ))}
      </ModalBody>
    </Modal>
  );
};

VersionModal.propTypes = {
  versionModal: PropTypes.bool,
  setVersionModal: PropTypes.func,
  previousVersions: PropTypes.array,

  selectedCountry: PropTypes.string,
  pageNo: PropTypes.number,
  siteReferenceId: PropTypes.string,
  divisionId: PropTypes.string,

  previousVersionsCount: PropTypes.number,
  certificateInboundEuId: PropTypes.string,
};

export default VersionModal;
