import {useLazyQuery, useQuery} from "@apollo/client";
import {
  Alert32,
  Down32,
  Kebab32,
  Remove48,
  Up32,
} from "@bphxd/ds-core-react/lib/icons";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  COPRO_US_SHIPMENTS_API_ALLOCATE_BOL,
  COPRO_US_SHIPMENTS_API_GET_BOL_DATA,
  COPRO_US_SHIPMENTS_API_RESET_ALLOCATE_BOL,
} from "graphql/coprocessing/shipments";
import _ from "lodash";
import {generateBOLExcel} from "modules/co-processing/components/Historical/ExportBOLButton";
import {ERRORS} from "modules/co-processing/constants/coProcessing";
import {
  BOL_LABEL_TEXT,
  WARNINGS,
} from "modules/co-processing/constants/shipments";
import {
  adjustDateByThreeMonths,
  displayDate,
  isDateTwoWeeksAfterShipment,
  toPST,
} from "modules/co-processing/helpers/dateHelper";
import moment from "moment";
import PropTypes from "prop-types";
import {formatNumber, useUserSettings} from "providers/userSettings";
import {useCallback, useMemo, useState} from "react";
import {toast} from "react-toastify";
import {
  Button,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import LoadingSpinner from "../../Shared/LoadingSpinner";
import ShipmentsBolResetPopover from "../ShipmentsBolResetPopover";
import {sumBOLVolumes} from "./computeVolumes";
import "./index.scss";
import SplitBOLModal from "./SplitBOLModal";

const BOLColumns = (decimalFormat, setSelectedBOLForSplit, shipmentDate) => [
  {
    id: "select-col",
    header: ({table}) => (
      <Input
        type="checkbox"
        checked={table.getIsAllRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({row}) => (
      <Input
        type="checkbox"
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
    size: 60,
  },
  {
    accessorKey: "start_load_date",
    header: "BOL date",
    cell: (cell) => {
      const value = cell.getValue();
      const isTwoWeeksAfter = isDateTwoWeeksAfterShipment(shipmentDate, value);

      const isSelected = cell.row.getIsSelected() || false;

      const formattedDate = displayDate(cell.getValue(), true);

      return (
        <div className="flex justify-left items-center">
          <span>{formattedDate}</span>
          {isSelected && isTwoWeeksAfter && (
            <Alert32 className="mb-1" color="#EDAC1A" />
          )}
        </div>
      );
    },
    size: 200,
  },
  {
    accessorKey: "outgoing_bol_number",
    header: "Bill of landing (BOL)",
    size: 200,
  },
  {
    accessorKey: "sap_material_name",
    header: "Material name",
    size: 200,
  },
  {
    accessorKey: "sap_material_id",
    header: "Material ID",
    size: 200,
  },
  {
    accessorKey: "credits_qualified",
    header: "RINs Qualified",
    cell: (cell) => {
      const value = cell.getValue();

      if (value === "true" || value === true) {
        return "Yes";
      }
      return "No";
    },
    size: 100,
  },
  {
    accessorKey: "truck_lifting_volume",
    header: "Truck lifting volume (bbl)",
    cell: (cell) => `( ${formatNumber(cell.getValue(), decimalFormat, 2)} )`,
    size: 200,
  },
  {
    accessorKey: "options",
    header: (
      <div>
        <Kebab32 />
      </div>
    ),
    id: "options",
    cell: ({row}) => (
      <UncontrolledDropdown>
        <DropdownToggle tag="div">
          <Kebab32 />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => setSelectedBOLForSplit(row.original)}>
            Split BOL
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
    size: 60,
  },
];

const renderVolumeSummaryRow = (
  text,
  volume,
  isFirst,
  decimalFormat,
  note = "",
) => (
  <tr className={`summary-row ${isFirst ? "first" : ""}`}>
    <td colSpan="3"> </td>
    <td colSpan="4">
      <div className={`d-flex ${note && "mt-3"}`}>
        <span className="first-text mr-auto w-[76%]">{text}</span>
        <span className="summary-volume ml-auto">
          {formatNumber(volume, decimalFormat, 2)}
        </span>
      </div>
      {note && (
        <div className="d-flex -mt-2">
          <span className="note-text mr-auto w-[76%]">{note}</span>
        </div>
      )}
    </td>
    <td> </td>
  </tr>
);

const renderShipmentInfo = (text, value) => (
  <p className="flex w-[30%]">
    <span className="mr-auto bol-modal-top-text">{text}</span>
    <span className="bol-modal-top-value">{value}</span>
  </p>
);

const displayStartEndDate = (startEndDate, shipmentDate) =>
  moment(toPST(startEndDate) || toPST(shipmentDate)).format("hh:mm A");

const BOLModal = ({onClose, shipmentId, shipmentData, onSave}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const [selectedBOLForSplit, setSelectedBOLForSplit] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  const toggle = useCallback(() => setIsOpen((prev) => !prev), [setIsOpen]);

  const partOne = shipmentData?.parts[0];
  const totalActualVolume = _.round(partOne?.total_actual_volume, 2);
  const allocatedVolume = _.round(partOne?.total_allocated_volume, 2);
  const shipmentDate = shipmentData?.shipment_date;

  const columns = useMemo(
    () => BOLColumns(decimalFormat, setSelectedBOLForSplit, shipmentDate),
    [decimalFormat, setSelectedBOLForSplit, shipmentDate],
  );

  const dateAfterThreeMonthOfEndDate = adjustDateByThreeMonths(
    partOne?.shipment_end_date,
  );

  const {loading, data, refetch} = useQuery(
    COPRO_US_SHIPMENTS_API_GET_BOL_DATA,
    {
      fetchPolicy: "no-cache",
      variables: {date_range: [dateAfterThreeMonthOfEndDate]},
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        const response = data?.bioLcCoproUsShipmentsApi?.body;
        if (!response) {
          toast.error(ERRORS.FAILED_LOAD);
          onClose();
        }
      },
    },
  );

  const [resetAllocateBol] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_RESET_ALLOCATE_BOL,
    {
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        const data = response?.bioLcCoproUsShipmentsApi;
        if (data?.error || data?.statusCode !== 200) {
          toast.error(data.error);
          return;
        }
        toast.success("Allocation reset successful.");
        onSave();
      },
    },
  );

  const BOLData = useMemo(
    () => data?.bioLcCoproUsShipmentsApi?.body?.bols || [],
    [data],
  );

  const [allocateBol] = useLazyQuery(COPRO_US_SHIPMENTS_API_ALLOCATE_BOL, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      const data = response?.bioLcCoproUsShipmentsApi;
      if (data?.error || data?.statusCode !== 200) {
        toast.error(data.error);
        return;
      }
      toast.success("Allocation successful.");
      onSave();
    },
  });

  const table = useReactTable({
    data: BOLData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
  });

  const sum = useMemo(
    () => sumBOLVolumes(rowSelection, BOLData, allocatedVolume),
    [rowSelection, BOLData, allocatedVolume],
  );

  const handleBOLSelection = useCallback(() => {
    let currentVolume = sum;
    let stopSelection = false;
    table.getRowModel().rows.forEach((row) => {
      if (stopSelection) return;
      const bolVolume = row.original?.truck_lifting_volume ?? 0;
      if (!row.getIsSelected()) {
        const newVolume = _.round(currentVolume + bolVolume, 2);
        if (newVolume <= totalActualVolume) {
          currentVolume = newVolume;
          row.toggleSelected(true);
        } else {
          // Stop the selection process if next BOL would exceed volume requirement
          stopSelection = true;
        }
      }
    });
  }, [table, totalActualVolume, sum]);

  const hasSelectedLateShipments = useMemo(() => {
    return Object.keys(rowSelection).some((index) => {
      const bol = BOLData[index];

      return isDateTwoWeeksAfterShipment(shipmentDate, bol.start_load_date);
    });
  }, [rowSelection, BOLData, shipmentDate]);

  return loading ? (
    <LoadingSpinner />
  ) : (
    <>
      <Modal
        id="bol-modal"
        fullscreen
        isOpen
        contentClassName="rounded-0"
        // z-index is necessary to keep bp-header visible at the top with the fullscreen
        zIndex="10"
      >
        <ModalHeader
          id="bol-modal-header"
          className="text-xl mt-11"
          close={<Remove48 onClick={onClose} />}
        >
          Allocate BOLs
        </ModalHeader>
        <ModalBody id="bol-modal-body">
          <p className="h2">Add BOLs to your truck rack shipment</p>
          <div
            className="flex flex-row justify-between w-[60%]"
            onClick={toggle}
            aria-hidden="true"
          >
            <span>{`${shipmentId} details`}</span>
            {isOpen ? <Up32 /> : <Down32 />}
          </div>
          <Collapse isOpen={isOpen}>
            {renderShipmentInfo(
              BOL_LABEL_TEXT.SHIPMENT_DATE,
              displayDate(shipmentDate, true),
            )}
            {renderShipmentInfo(
              BOL_LABEL_TEXT.SHIPMENT_START_TIME,
              displayStartEndDate(partOne?.shipment_start_date, shipmentDate),
            )}
            {renderShipmentInfo(
              BOL_LABEL_TEXT.SHIPMENT_END_TIME,
              displayStartEndDate(partOne?.shipment_end_date, shipmentDate),
            )}
            {renderShipmentInfo(
              BOL_LABEL_TEXT.ESTIMATED_SHIPMENT_VOLUME,
              formatNumber(
                shipmentData.total_estimated_volume,
                decimalFormat,
                2,
              ),
            )}
          </Collapse>
          {hasSelectedLateShipments && (
            <div
              className="flex justify-left items-center w-[60%] mt-3 mb-5 border-2 rounded border-[#FFEE5C] bg-[#FFFFF0] py-3 px-3"
              data-test="bol-modal-two-weeks-warning"
            >
              <Alert32 className="mb-1" color="#EDAC1A" />
              <span className="bol-modal-top-value">
                {WARNINGS.BOL_OUTSIDE_SHIPMENT_DATE}
              </span>
            </div>
          )}

          <div className="bol-modal-buttons flex w-[60%] items-center">
            <p className="flex w-1/2">
              <span className="mr-auto bol-modal-top-text">
                {BOL_LABEL_TEXT.ACTUAL_SHIPMENT_VOLUME}
              </span>
              <span className="bol-modal-top-value">
                {formatNumber(totalActualVolume, decimalFormat, 2)}
              </span>
            </p>

            <Button
              className="btn btn-light rounded-0 ml-auto"
              onClick={handleBOLSelection}
            >
              Auto Allocate
            </Button>
          </div>
          <div className="bol-modal-table w-[60%]">
            <Table>
              <thead>
                <tr>
                  {table.getFlatHeaders().map((header) => (
                    <th
                      key={header.id}
                      style={{
                        width:
                          header.getSize() === 150 ? "auto" : header.getSize(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id} className="standard-row">
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            style={{
                              backgroundColor: row.getIsSelected()
                                ? "#f6f8fd"
                                : "",
                              width:
                                cell.column.getSize() === 150
                                  ? "auto"
                                  : cell.column.getSize(),
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}

                {renderVolumeSummaryRow(
                  BOL_LABEL_TEXT.ALLOCATED_VOL,
                  sum,
                  false,
                  decimalFormat,
                  "Note: Includes previous allocated volume",
                )}
              </tbody>
            </Table>
          </div>
          <div className="bol-modal-buttons flex w-[60%]">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setShowPopover(true);
              }}
              className="btn btn-light rounded-0 mr-auto"
              data-test="reset-allocation-button"
              id="reset-allocation-button"
            >
              Reset allocation
            </Button>
            <ShipmentsBolResetPopover
              showPopover={showPopover}
              setShowPopover={setShowPopover}
              resetFunction={() => resetAllocateBol({variables: {shipmentId}})}
            />
            <Button
              color="standard-secondary"
              className="btn btn-light rounded-0 mr-2 ml-auto"
              onClick={() => generateBOLExcel(BOLData)}
            >
              Export BOLs
            </Button>
            <Button className="btn btn-light rounded-0 mr-2" onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={_.isEmpty(rowSelection)}
              className="btn btn-light rounded-0"
              onClick={() =>
                allocateBol({
                  variables: {
                    request: {
                      dtn_bol_layer_id: Object.keys(rowSelection).map(
                        (index) => BOLData[index].dtn_bol_layer_id,
                      ),
                      dtn_shipment_id: shipmentData.shipment_id,
                    },
                  },
                })
              }
            >
              Save
            </Button>
          </div>
        </ModalBody>
      </Modal>
      {!_.isEmpty(selectedBOLForSplit) && (
        <SplitBOLModal
          allocatedVolume={sum}
          shipmentVolume={totalActualVolume ?? 0}
          bol={selectedBOLForSplit}
          onClose={() => setSelectedBOLForSplit({})}
          onSave={async () => {
            try {
              setSelectedBOLForSplit({});
              const response = await refetch();
              if (response?.data?.bioLcCoproUsShipmentsApi?.body?.bols) {
                handleBOLSelection();
              } else {
                toast.error(ERRORS.FAILED_LOAD);
                onClose();
              }
            } catch (error) {
              console.error("An error occurred:", error);
            }
          }}
        />
      )}
    </>
  );
};

BOLModal.propTypes = {
  onClose: PropTypes.func,
  shipmentId: PropTypes.string,
  shipmentData: PropTypes.object,
  onSave: PropTypes.func,
};

export default BOLModal;
