import {useAccount, useMsal} from "@azure/msal-react";
import {EditDocumentAPI} from "graphql/docManager/IncomingDocs";
import PropTypes from "prop-types";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {Button, Modal, ModalBody, ModalFooter, Spinner} from "reactstrap";

const EditModal = ({
  editModal,
  setEditModal,
  rowData,
  certificateInboundEuId,
  selectedCountry,
  pageNo,
  siteReferenceId,
  divisionId,
}) => {
  const [isProcessing, setProcessing] = useState(false);
  const navigate = useNavigate();

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const handleCancelClick = () => {
    setEditModal(false);
  };

  const handleContinueClick = async () => {
    setProcessing(true);
    const {data: response} = await EditDocumentAPI({
      certificateInboundEuId,
      siteReferenceId,
      divisionId,
      userAction: "revert",
      userId: account.username,
    });

    if (response?.bioLcUpdateIncomingDocDataApi?.statusCode === 204) {
      setProcessing(false);
      toast.success(
        response?.bioLcUpdateIncomingDocDataApi?.message ??
          "Successfully updated the document",
      );
      navigate({
        pathname: `/document-manager/incoming/${selectedCountry}/detailedView/${encodeURIComponent(
          certificateInboundEuId,
        )}`,
        search: `?pageNo=${pageNo}&siteReferenceId=${siteReferenceId}&divisionId=${divisionId}`,
      });
      setEditModal(false);
    } else {
      setProcessing(false);
      toast.error(
        response?.bioLcUpdateIncomingDocDataApi?.error ??
          "Something went wrong, failed to update the document",
      );
    }
  };

  return (
    <Modal size="sm" isOpen={editModal} className="modal-dialog-centered">
      <ModalBody className="text-center">
        <p>Edit document</p>

        <p className="text-[13px] font-light">
          Are you sure you want to edit the document? If you continue, the
          document status will change to ready to accept.
        </p>
      </ModalBody>
      <ModalFooter className="p-0 d-block">
        <div className="row g-0 m-0 modal-footer-row">
          <div className="col-6 d-grid p-0">
            <Button
              color="darker-tertiary"
              className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
              onClick={handleCancelClick}
              onKeyDown={handleCancelClick}
            >
              Cancel
            </Button>
          </div>
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              className="border-0 rounded-0 py-4 px-0 bg-transparent text-default"
              onClick={handleContinueClick}
              onKeyDown={handleContinueClick}
              disabled={isProcessing}
            >
              {isProcessing && (
                <Spinner size="sm" className="btn-icon-prefix" />
              )}
              Continue
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

EditModal.propTypes = {
  editModal: PropTypes.bool,
  setEditModal: PropTypes.func,
  rowData: PropTypes.object,
  certificateInboundEuId: PropTypes.string,
  selectedCountry: PropTypes.string,
  pageNo: PropTypes.number,
  siteReferenceId: PropTypes.string,
  divisionId: PropTypes.string,
};

export default EditModal;
